<template>
  <v-container fluid>
    <PageHeader>
      <span slot="courses" class="font-weight-light">FACULTY </span> LIST
    </PageHeader>

    <v-overlay :absolute="absolute" :value="overlay" z-index="90">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-row>
      <v-col>
        <v-btn
          class="ma-2 secondary--text font-weight-bold"
          to="/faculty_management/new_faculty"
          color="primary"
          v-if="getters_abilities.includes('create_faculty_access')"
        >
          CREATE NEW FACULTY
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="basketWithIndex"
          class="elevation-2"
          item-key="id"
          disable-pagination
          :loading="isLoaded"
          hide-default-footer
        >
          <template v-slot:item.faculty_name="faculty_name">
            <v-edit-dialog
              single-line
              @save="
                saveFacultyChangesBtn({
                  id: faculty_name.item.id,
                  name: faculty_name.item.faculty_name,
                  faculty_code: faculty_name.item.faculty_code,
                  text: newFacultyText,
                })
              "
              @open="newFacultyText = faculty_name.item.faculty_name"
            >
              {{ faculty_name.item.faculty_name }}
              <template v-slot:input>
                <v-text-field
                  class="mt-5"
                  v-model="newFacultyText"
                  label="Edit"
                  outlined
                  hint="Press Enter key when saving"
                  :rules="[(v) => !!v || 'Duration is required']"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-edit-dialog
              single-line
              v-if="getters_abilities.includes('delete_faculty_access')"
            >
              <v-btn dark right large icon>
                <v-icon dark class="red--text"> mdi-close-circle </v-icon>
              </v-btn>

              <template v-slot:input>
                <v-card flat>
                  <v-card-text class="pa-3 ma-0">
                    Do You Want to Delete <b>{{ item.faculty_name }}</b> ?
                  </v-card-text>
                  <v-card-actions class="px-0 ma-0">
                    <v-btn
                      color="red"
                      dark
                      block
                      @click="removeFacultyBtn(item.id, item.faculty_name)"
                    >
                      Delete
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.department="department">
            <v-edit-dialog @open="openFacultyDept(department.item)">
              View Department(s)
              <template #input>
                <v-autocomplete
                  :menu-props="{ top: true, offsetY: true }"
                  outlined
                  readonly
                  class="my-1"
                  multiple
                  :items="basket"
                  item-text="department_name"
                  item-key="id"
                  v-model="basket"
                  :rules="[(v) => !!v || 'Department is required']"
                  hide-details="auto"
                >
                  <template v-slot:selection="{ item }">
                    <v-chip label color="primary">{{
                      item.department_name
                    }}</v-chip>
                  </template>
                </v-autocomplete>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:no-data>
            <span class="subheading font-weight-bold">Not available</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <Response v-if="deleteResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>

<script>
  import { computed, getCurrentInstance, provide, reactive, toRefs } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import PageHeader from "@/components/slots/PageHeader";
  import Response from "@/components/ActionResponse/Response";

  export default {
    components: { PageHeader, Response },
    setup() {
      const vm = getCurrentInstance()
      const { getFaculty, removeFaculty, saveFacultyChanges, signOut } =
        useActions([
          "getFaculty",
          "removeFaculty",
          "saveFacultyChanges",
          "signOut",
        ]);

      const { getters_faculty, getters_dup_fac_name, getters_abilities } =
        useGetters([
          "getters_faculty",
          "getters_dup_fac_name",
          "getters_abilities",
        ]);

      getFaculty()
        .then(() => {
          facultyTable.isLoaded = false;
        })
        .catch((e) => {
          color.value = "error";
          switch (e.response.status) {
            case 423:
              msgBody.value = e.response.data.message;
              signOut().then(() => {
                vm.proxy.$router.replace({
                  name: "Login",
                });
              });
              break;

            default:
              msgBody.value = "Try Again Later Or Call The System Administrator";
              break;
          }
        });

      const facultyTable = reactive({
        isLoaded: true,
        headers: [
          { text: "FACULTY CODE", value: "faculty_code" },
          { text: "FACULTY NAME", value: "faculty_name" },
          { text: "DEPARTMENT NAME", value: "department" },
          { text: "ACTIONS", value: "actions", sortable: false },
        ],
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        color: "",
        levelItem: "",
        deleteResponse: false,
        absolute: true,
        overlay: false,
        newFacultyText: "",
        basket: [
          {
            department_id: null,
            department_name: "",
            faculty_number: null,
          },
        ],
      });

      const {
        deleteResponse,
        msgHeader,
        msgBody,
        msgIcon,
        overlay,
        basket,
        color,
      } = toRefs(facultyTable);

      provide("color", color);

      const basketWithIndex = computed(() => {
        return getters_faculty.value.map((items /* index */) => ({
          ...items,
          /* 	index: index + 1, */
        }));
      });

      const removeFacultyBtn = (id, faculty) => {
        deleteResponse.value = false;
        overlay.value = true;

        removeFaculty(id)
          .then(() => {
            overlay.value = false;
            getFaculty().then(() => {
              facultyTable.isLoaded = false;
            });
          })
          .catch((e) => {
            overlay.value = false;
            msgIcon.value = "mdi-close-circle";
            deleteResponse.value = true;
            msgHeader.value = "Error";

            if (e.response.status === 500) {
              msgBody.value = `Faculty of ${faculty} cannot be deleted.\
                                  					It is used in other component(s)`;
            } else if (e.response.status === 423) {
              msgBody.value = e.response.data.message;
              signOut().then(() => {
                vm.proxy.$router.replace({
                  name: "Login",
                });
              });
            } else {
              msgBody.value = "This action is unauthorized";
            }
          });
      };

      const saveFacultyChangesBtn = (faculty) => {
        if (faculty.name === faculty.text) return;
        deleteResponse.value = false;
        overlay.value = true;
        saveFacultyChanges(faculty)
          .then(() => {
            getFaculty()
              .then(() => {
                facultyTable.isLoaded = false;
              })
              .catch((e) => {
                if (e.response.status === 423) {
                  deleteResponse.value = true;
                  msgBody.value = e.response.data.message;
                  signOut().then(() => {
                    vm.proxy.$router.replace({
                      name: "Login",
                    });
                  });
                }
              });
            overlay.value = false;
            if (getters_dup_fac_name.value) {
              deleteResponse.value = true;

              msgHeader.value = "Cannot Rename";
              msgBody.value =
                "Faculty of " +
                getters_dup_fac_name.value +
                " already exists.Specify another name\n";
              msgIcon.value = "mdi-close-circle";
              return;
            }
          })
          .catch((e) => {
            overlay.value = false;
            deleteResponse.value = true;
            msgHeader.value = "Error";
            msgIcon.value = "mdi-close-circle";
            if (e.response.status === 500) {
              msgBody.value =
                "Faculty of " +
                faculty +
                " cannot be edited. It is used in other component(s)";
            } else if (e.response.status === 423) {
              msgBody.value = e.response.data.message;
              signOut().then(() => {
                vm.proxy.$router.replace({
                  name: "Login",
                });
              });
            } else {
              msgBody.value = "This action is unauthorized";
            }
          });
      };

      const openFacultyDept = (item) => {
        basket.value = [];
        basket.value = item.departments;
        console.log(basket.value);
      };

      return {
        ...toRefs(facultyTable),
        basketWithIndex,
        removeFacultyBtn,
        saveFacultyChangesBtn,
        openFacultyDept,
        getters_abilities,
      };
    },
  };
</script>
